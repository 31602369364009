.throbble-wrapper {
    height: calc(100vh - 80px);
    width: 100vw;
    max-width: 600px;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 25px;
    z-index: 999999;
    position: fixed;

    .throbble-inner {
        width: 100%;
        min-height: 120px;
        padding: 0 20px;
        color: #fff;
        background-color: rgba(0, 54, 59, 0.8);
        border: 2px solid #fff;
        border-radius: 10px;
        text-align: center;

        .spinner-block {
            border-radius: 50%;
            background-color: rgba(0, 0, 0, 0.4);
            width: fit-content;
            padding: 2px 2px 0 2px;
            font-size: 14px;
            margin: 0 auto ;
        }

        .message-block {
            font-size: 12px;
        }
    }
}