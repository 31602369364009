 /* #region Buttons*/
 .btn {
     &-action {
         position: relative;
         display: block;
         font-family: $defaultFont;
         font-size: 12px;
         font-weight: 600 !important;
         color: #fff !important;
         background-color: $colDarkOrange !important;
         padding: 14px 25px;
         border-radius: 4px;
         border: 1px solid $colDarkOrange;
         box-shadow: none !important;
         text-shadow: none !important;
         margin: 4px 6px;
         text-align: left;
         text-decoration: none;

         i {
             color: #fff;
         }

         &[disabled] {
             background-color: lighten($color: $colDarkOrange, $amount: 25%) !important;
             border-color: lighten($color: $colDarkOrange, $amount: 25%);
         }
     }

     &-alt {
         position: relative;
         display: block;
         font-family: $defaultFont;
         font-size: 12px;
         font-weight: 600 !important;
         color: $textLight !important;
         background-color: #fff !important;
         padding: 14px 25px;
         border-radius: 4px;
         border: 1px solid #e4e5e6;
         box-shadow: none !important;
         text-shadow: none !important;
         margin: 4px 6px;
         text-align: left;
         text-decoration: none;

         i {
             color: #c2c2c2;
         }

         &.cancel {
             i {
                 color: #c2c2c2;
             }
         }
     }

     &-mark-all {
        position: relative;
        display: inline-block;
        font-family: $defaultFont;
        font-size: 10px;
        text-transform: uppercase;
        font-weight: 600 !important;
        color: $textLight !important;
        background-color: #fff !important;
        padding: 14px 20px;
        border-radius: 4px;
        border: 1px solid #e4e5e6;
        box-shadow: none !important;
        text-shadow: none !important;
        margin: 4px 6px;
        text-align: center;
        text-decoration: none;

        i {
            color: #c2c2c2;
        }

        &.cancel {
            i {
                color: #c2c2c2;
            }
        }
    }


     &-rate {
         position: relative;
         display: inline-block;
         font-family: $defaultFont;
         font-size: 14px;
         font-weight: 300 !important;
         color: #fff !important;
         background-color: $colDarkOrange !important;
         border: none;
         box-shadow: none;
         width: 140px !important;
         padding: 10px 18px;
         border-radius: 4px;
         text-shadow: none !important;
         margin: 10px 2px 10px 6px;
         text-decoration: none;

         i {
             color: #fff;
         }
     }

     &-more-info {
         background-color: transparent !important;
         box-shadow: none;
         border: none;
         color: #464646;
         font-weight: bold;
         display: inline-block !important;
         position: relative;
         font-size: 11px;
         margin: 4px 0 0px 0;
         text-transform: uppercase;
         /* border: 1px solid #cdcdcd; */
         padding: 8px 8px 0 8px;
         text-align: center;

         i {
             color: #f1592a;
         }
     }

     &-grey {
         position: relative;
         display: inline-block;
         font-family: $defaultFont;
         font-size: 12px;
         font-weight: normal !important;
         color: $textLight !important;
         background-color: #e1e1e1 !important;
         border: none;
         box-shadow: none;
         /*width: 200px !important;*/
         padding: 14px 25px;
         border-radius: 4px;
         text-shadow: none !important;
         margin: 4px 10px 16px 6px;
         text-align: left;
         text-decoration: none;

         i {
             color: #fff;
         }
     }

     &-editQ {
         position: relative;
         display: inline-block;
         font-family: $defaultFont;
         font-size: 12px;
         font-weight: 300 !important;
         color: #fff !important;
         background-color: $colDarkOrange !important;
         border: none;
         box-shadow: none;
         padding: 8px 18px;
         border-radius: 4px;
         text-shadow: none !important;
         margin: 10px 0px 0px 6px;
         text-decoration: none;

         i {
             color: #fff;
         }
     }

     &-small-action {
         position: relative;
         display: block;
         font-family: $defaultFont;
         font-size: 12px !important;
         font-weight: normal !important;
         color: #fff !important;
         background-color: $colDarkOrange !important;
         padding: 8px 8px;
         border-radius: 4px;
         border: 1px solid $colDarkOrange;
         box-shadow: none !important;
         text-shadow: none !important;
         margin: 4px 6px;
         text-align: left;
         text-decoration: none;

         i {
             color: #fff;
             text-align: right;
         }
     }

     &-iconless-action {
         position: relative;
         display: block;
         font-family: $defaultFont;
         font-size: 12px !important;
         font-weight: normal !important;
         color: #fff !important;
         background-color: $colDarkTeal !important;
         padding: 8px 8px;
         border-radius: 4px;
         border: 1px solid $colDarkTeal;
         box-shadow: none !important;
         text-shadow: none !important;
         margin: 4px 6px;
         text-align: center;
     }

     &-icon {
         position: relative;
         display: block;
         font-family: $defaultFont;
         font-size: 12px;
         font-weight: normal !important;
         color: $textLight !important;
         background-color: #e1e1e1 !important;
         border: none;
         box-shadow: none;
         /*width: 200px !important;*/
         padding: 14px 25px;
         border-radius: 4px;
         text-shadow: none !important;
         margin: 4px 10px 4px 6px;
         text-align: left;
         width: calc(100% - 50px);

         & i {
             color: $colDarkTeal;
             position: absolute;
             top: 15px;
             right: 6px;
             font-size: 15px;
             font-weight: 900;
         }
     }

     &-list {
         i {
             color: $colDarkOrange;
         }
     }

     &-login {
         background-color: $colDarkOrange;
         padding: 10px 15px;
         text-transform: uppercase;
         text-align: left;
         color: #fff;
         border: none;
         border-radius: 3px;
         font-weight: normal;
         position: relative;
         width: 100%;
         max-width: 100px;
         display: inline-block;
         height: 50px;
         font-size: 11px;
     }
 }

 /* #endregion */