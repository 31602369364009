.plr-10 {
    padding-left: 10px;
    padding-right: 10px;

    .mediaElements {
        width: 100%;
        text-align: center;

        .input-media-container {
            width: 90%;
            height: auto;
        }

        .retryBtn {
            background-color: #f1592a;
            border-radius: 5px;
            border: none;
            color: white;
            width: 20%;
            margin-top: 5%;
        }

        #mediaCapture {
            width: 100%;

            #audioCapture {
                width: 90%;
            }

            .takePicBtns {
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                
                .takePic {
                    width: 20%;
                    font-size: 15px;
                    border: none;
                    border-radius: 15px;
                    background-color: #f1592a;
    
                }
            }


            .recordingBtns {
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;

                .recordingBtn {
                    border-radius: 5px;
                    background-color: #f1592a;
                    color: white;
                    border: none;
                    width: 20%;

                    img {
                        width: 50%;
                    }
                }

                .inactive {
                    background-color: grey;
                    color: black;
                }
            }

        }
    }

    .addTextArea {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        .textArea {
            width: 83%;
            text-align: right;

            textarea {
                width: 100%;
                border-radius: 0 !important;
                box-shadow: rgba(0,0,0,0.1) 0px 1px 2px 1px inset;
                border: 2px solid rgba(0,0,0,0.2);
            }
            
            p {
                text-align: end;
                font-size: 12px;
            }
        }

        .post-button-container {

            .btn-circular-post {
                display: block;
                width: 50px;
                height: 50px;
                line-height: 40px;
                border-radius: 50%;
                text-align: center;
                color: #f1592a;
                box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.1);
    
                img {
                    height: 35px;
                    width: auto;
                    padding-top: 3px;
                }
            }

            p {
                font-size: 13px;
                text-align: center;
            }
        }

    }

    .table-attach {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;

        .attachments {
            width: 25%;
            height: 25%;
            margin-right: 0 !important;
            text-align: center;
            display: block;
            text-shadow: none;
            margin: 5px;
            transition: all .2s;

            img {
                width: 50%;
                height: auto;
            }

            p {
                font-size: 12px;
            }

            label {
                font-size: 12px;
                overflow-wrap: break-word;
            }
        }
    }

    .text-right {
        text-align: right !important;

        .loader {
            position: absolute;
            top: 50%;
            margin-left: 10px;
            margin-right: 10px;
            width: 90%;
            border: none;
            background-color: #00363b;
            opacity: .9;
            text-align: center;
            box-shadow: 2px 1px 3px 2px rgba(0, 0, 0, .3);
            border-radius: 5px;
            color: white;

        }
    }
}

.btn-action-icon {
    background-color: #f1592a;
    color: #fff !important;
    display: inline-block;
    padding: 10px 20px;
    text-shadow: none;
    border-radius: 5px;
    text-align: center;
    font-weight: normal !important;
    text-transform: uppercase;
    font-size: 0.8em;
    text-decoration: none;
    border: none;

    img {
        height: 25px;
        margin-bottom: 4px;
    }
}

.appFeedback{
    background-color: #f1592a;
    color: #fff !important;
    display: inline-block;
    padding: 10px 20px;
    text-shadow: none;
    border-radius: 10px;
    font-weight: normal !important;
    text-transform: uppercase;
    font-size: 0.8em;
    text-decoration: none;
    border: none;
}