﻿#ptr-container{
  width: inherit;
}
header {
  background-color: #00363b;
  color: #fff;
  text-align: center;
  padding: 10px 0;
  z-index: 9999;
  width: 100%;
  max-width: 760px;  
  position: fixed;
  top: 0;
}



@mixin footer-button($icon, $activeIcon) {
  display: inline-block;
  width: 43px;
  height: 36px;
  background-color: transparent !important;
  background: url($icon) no-repeat center center;
  background-size: cover;
  border: none;
  box-shadow: none;
  text-shadow: none;
  border-radius: 0;

  &:hover,
  &.active {
    background: url($activeIcon) no-repeat center center;
    background-size: cover;
  }

  @content;

}

footer {
  background-color: #eee;
  color: #000;
  font-size: 0.8em;
  padding: 12px 0 8px 0;
  border-top: 1px solid #ccc;
  width: 100%;
  //max-width: 600px;
  z-index: 9999;
  width: 100%;
  max-width: 760px;
  //position: fixed;
  
  position: fixed;
  bottom: 0;

  & .nav-home {
    @include footer-button("../assets/icons/icon-grey-home-alt.svg", "../assets/icons/icon-home-alt.svg") {
      width: 36px;

    }
  }

  & .nav-questions {
    @include footer-button("../assets/icons/icon-grey-question.png", "../assets/icons/icon-question.png") {}

  }

  & .nav-contributions {
    @include footer-button("../assets/icons/icon-grey-contributions.png", "../assets/icons/icon-contributions.png") {}

  }

  & .nav-insights {
    @include footer-button("../assets/icons/icon-grey-insights.png", "../assets/icons/icon-insights.png") {}

  }

  & .nav-createPost {
    @include footer-button("../assets/icons/icon-grey-edit.svg", "../assets/icons/icon-edit.svg") {}
  }

  & .nav-createPost {
    @include footer-button("../assets/icons/icon-grey-edit.svg", "../assets/icons/icon-edit.svg") {
      width: 36px;
      height: 32px;
    }
  }

  & .nav-menuMain {
    @include footer-button("../assets/icons/icon-grey-bars.svg", "../assets/icons/icon-grey-bars.svg") {
      width: 30px;
      height: 26px;
    }
  }
}

.content-padding {
  padding: 40px 0 80px 0;
}

.side-menu {
  padding-top: 30px;
  padding-bottom: 80px;

  & ul {
    list-style: none;
    margin-left: 0;
    padding-left: 0;

    & li {
      padding: 10px 8px;
      border-bottom: 1px solid #ccc;
      color: #000;

      & a {
        color: #000;
        text-decoration: none;
      }
    }
  }

  & .btn-close {
    border: none;
    background-color: #fff;
    padding: 0;
  }

  & .versionNum {
    text-align: center;
    color: #ccc;
  }
}

.search-container {
  position: absolute;
  background-color:  lighten($color: #00363b, $amount: 25%);
  top: 40px;
  left: 0;
  padding:15px;
  width: 100%;
}