.options-form {
  h1 {
    text-shadow: none;
    font-weight: 400 !important;
    font-size: 26px;
    padding: 0.5rem 0;
    margin: 0;
    text-align: center;
    text-transform: uppercase;
    background-color: #fff;
  }

  .plr-10 {
    padding-left: 10px;
    padding-right: 10px;
  }

  select {
    border: none;
    border-radius: 5px;
    font-weight: 600;
    font-size: 1em;
    width: 100%;
    text-align: center;
    box-shadow: rgba(0, 0, 0, .1) 0px 1px 3px 2px;
    height: 35px !important;
  }

  label {
    font-size: 14px;
  }

  .io-block-a {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
}

.onoffswitch {
  position: relative;
  width: 55px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  margin-left: 15%;
}

.onoffswitch-checkbox {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid black;
  border-radius: 5px;
}

.onoffswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before,
.onoffswitch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 25px;
  padding: 0;
  line-height: 25px;
  font-size: 10px;
  color: white;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
  box-sizing: border-box;
}

.onoffswitch-inner::after {
  content: "NO";
  padding-right: 10px;
  background-color: #08910373;
  color: #F7F7F7;
  text-align: right;
}

.onoffswitch-inner::before {
  content: "YES";
  padding-left: 10px;
  background-color: #fa0328;
  color: #070707;
}

.onoffswitch-switch {
  display: block;
  width: 14px;
  margin: 5.5px;
  background: #FFFFFF;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 30px;
  border: 2px solid #999999;
  border-radius: 9px;
  transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-switch {
  right: 0px;
}