.logo-bar {
    height: 200px;
    background-color: $colDarkTeal;
    text-align: center;
    padding-top: 20px;
    margin-top: 0;
}

.landing-container {
    height: 100%;
    min-height: calc(100vh - 200px);
    width: 100%;
    background: url("../../public/img/login/login-background.jpg") no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;


    .welcome-block {
        color: #fff;
        font-size: 16px;
        font-weight: 100;
    }

    form {
        padding: 15px 5%;
        position: relative;

        input {
            background-color: rgba(255, 255, 255, .5);
            color: #fff !important;
            border-radius: 0.375rem !important;

            &:focus {
                color: $textLight !important;
            }

            &::placeholder {
                color: #fff;
            }
        }
    }

    @keyframes rightFadeInOut {
        0% {
            position: absolute;
            right: -3rem;
            opacity: 0;
        }

        75% {
            position: absolute;
            right: 1rem;
            opacity: 1;
        }

        100% {
            opacity: 0;
            right: 1rem;
        }
    }

    @keyframes FadeIn {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    .animation-box {
        width: 100%;
        position: relative;
        font-family: $defaultFont;
        font-size: 20px;
        font-weight: 300;

        & #first-line {
            color: #fff;
            opacity: 0;
        }

        & #second-line {
            color: #fff;
            opacity: 0;
        }

        & #third-line {
            color: #fff;
            opacity: 0;
        }

        & div {
            position: absolute;
            text-align: center;
            top: 0;
            left: 0;
            height: 24px;
            width: 100%;
            text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.55);
        }
    }
}

.pnlWelcome {
    max-width: 100%;
    height: calc(100vh - 200px);
    font-family: $defaultFont;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    text-shadow: none;
    text-align: center;

    .login-block {
        padding: 15px 10%;
        position: relative;
    }

    a,
    p {
        font-size: 11px;
    }

    /*input[type=email] {
font-family: $defaultFont;
border: 1px solid #fff;
background-color: rgba(255,255,255,0.25);
color: #333 !important;
line-height: 150%;
padding: 4px 0;
text-indent: 15px;
width: 100%;
margin-bottom: 12px;

& ::-webkit-input-placeholder {
  color: $textLight;
  text-shadow: none;
  font-style: italic;
}
}*/
    #gotoLogin {
        background-color: $colDarkOrange;
        padding: 10px 15px;
        text-transform: uppercase;
        text-align: left;
        color: #fff;
        border: none;
        border-radius: 3px;
        font-weight: normal;
        position: relative;
        width: 100%;
        max-width: 125px;
        display: inline-block;

        & i {
            position: absolute;
            right: 12px;
            top: 10px;
        }

        &:disabled {
            background-color: #ccc;
            opacity: 1;
            font-style: italic;
            color: #eee;
        }
    }

    #termsAccept {
        font-size: 20px !important;
    }
}


.welcome-note {
    background-color: rgba(0, 54, 59, 0.7);
    border: 1px solid #fff;
    color: #fff;

    a {
        color: #fff;
        text-decoration: underline !important;

        & span.action {
            position: relative;
            font-size: 12px !important;
            text-align: left;
            margin-left: 16px !important;

            &::before {
                content: "\f111";
                font-family: "Font Awesome 6 Pro";
                color: $colDarkOrange;
                position: absolute;
                top: 0;
                left: -14px;
                font-size: 11px;
                font-weight: bold;
            }
        }
    }


    #iEmailText {
        font-family: $defaultFont;
        border: 1px solid #fff;
        background-color: rgba(255, 255, 255, .5);
        color: #fff !important;
        line-height: 100%;
        width: 100%;

        &::placeholder {
            color: $textLight;
            text-shadow: none;
            font-style: italic;
        }
    }
}

.multigroup {
    .btn-list {
        position: relative;
        display: block;
        font-family: "Montserrat";
        font-size: 11px;
        font-weight: 600 !important;
        color: #464646 !important;
        background-color: lightgray;
        padding: 14px 25px;
        border-radius: 4px;
        text-shadow: none;
        margin: 4px 6px;
        text-align: left;
        border: 1px solid black;
        text-transform: uppercase;
        text-decoration: none;
    }
}