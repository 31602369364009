.welcomeName {
    font-family: $defaultFont;
    font-size: 16px;
    color: $textDark;
    font-weight: 300;
}


a {
    border: none;
    text-shadow: none;
    box-shadow: none;
    background-color: transparent;
}

.plr-10 {
    padding-left: 10px;
    padding-right: 10px;
}

.full-profile {
    color: $colDarkTeal;


    & .tapText {
        font-family: $defaultFont;
        font-size: 11px;
        color: #777;
        font-weight: 300;
        text-align: center;
    }

    .username {
        font-family: $defaultFont;
        font-size: 18px;
        color: $colDarkTeal;
        font-weight: 200;
    }

    a {
        border: none;
        text-shadow: none;
        box-shadow: none;
        background-color: transparent;
    }

    .light {
        color: #a7a9ac !important;
    }

    .user-info {
        background-color: #e8e8e9;
        color: #636363;
        font-size: 11px;
        text-align: left;
        padding: 10px;
        text-shadow: none;

        & b {
            color: $colDarkTeal;
            font-weight: 800;
            text-transform: uppercase;
        }

        & a {
            color: $colLightOrange;
            font-weight: 300;

            &:hover {
                color: $colDarkOrange;
            }
        }
    }
}

.orange-background {
    background-color: $colDarkOrange;
    color: #fff;
    margin-bottom: 4px;
    padding: 10px 0;
}


.cv-container {
    .accordion {
        --bs-accordion-border-radius: 0%;

        .accordion-item {
            &:last-of-type {
                border-bottom-left-radius: 0%;
                border-bottom-right-radius: 0%;
            }

            h4 {
                font-family: $defaultFont;
                color: #fff !important;
                position: relative;
            }

            .accordion-button {
                color: $colDarkTeal !important;
                background-color: #f3f3f4;
                font-size: 13px;
                padding: 12px;
                border-bottom: $colDarkOrange 2px dashed;
                border-left: $colDarkOrange 4px solid;
                border-radius: 0%;
                position: relative;
                background: #f3f3f4 !important;
                color: $colDarkTeal !important;
                font-weight: 600 !important;
                text-transform: uppercase;
                position: relative !important;
                overflow: visible !important;
                text-shadow: none;
                padding-left: 20px;

                &::before {
                    content: '\f0da' !important;
                    font-family: "Font Awesome 6 Pro";
                    color: $colDarkOrange !important;
                    position: absolute !important;
                    top: 4px !important;
                    left: -4px !important;
                    font-size: 22px !important;
                    text-shadow: none;
                }

                &::after {
                    content: '\f13a';
                    font-family: "Font Awesome 6 Pro";
                    color: #aaa;
                    font-size: 20px;
                    background-image: none !important;
                }
            }
        }
    }
}

.collab-container {
    .accordion {
        --bs-accordion-border-radius: 0%;

        .accordion-item {
            &:last-of-type {
                border-bottom-left-radius: 0%;
                border-bottom-right-radius: 0%;
            }

            h4 {
                font-family: $defaultFont;
                color: #fff !important;
                position: relative;
            }

            .accordion-header{
                &.question > button{
                    border-bottom: $colQuestion 2px dashed;
                    border-left: $colQuestion 4px solid;

                    &::before {
                        content: '\f0da' !important;
                        font-family: "Font Awesome 6 Pro";
                        color: $colQuestion !important;
                        position: absolute !important;
                        top: 7px !important;
                        left: -5px !important;
                        font-size: 22px !important;
                        text-shadow: none;
                    }
                }

                &.insights > button{
                    border-bottom: $colInsight 2px dashed;
                    border-left: $colInsight 4px solid;

                    &::before {
                        content: '\f0da' !important;
                        font-family: "Font Awesome 6 Pro";
                        color: $colInsight !important;
                        position: absolute !important;
                        top: 7px !important;
                        left: -5px !important;
                        font-size: 22px !important;
                        text-shadow: none;
                    }
                }

                &.insight > button{
                    border-bottom: $colInsight 2px dashed;
                    border-left: $colInsight 4px solid;

                    &::before {
                        content: '\f0da' !important;
                        font-family: "Font Awesome 6 Pro";
                        color: $colInsight !important;
                        position: absolute !important;
                        top: 7px !important;
                        left: -5px !important;
                        font-size: 22px !important;
                        text-shadow: none;
                    }
                }

                &.contribution > button{
                    border-bottom: $colContribution 2px dashed;
                    border-left: $colContribution 4px solid;

                    &::before {
                        content: '\f0da' !important;
                        font-family: "Font Awesome 6 Pro";
                        color: $colContribution !important;
                        position: absolute !important;
                        top: 7px !important;
                        left: -5px !important;
                        font-size: 22px !important;
                        text-shadow: none;
                    }
                }

                &.score > button{
                    border-bottom: $colDarkOrange 2px dashed;
                    border-left: $colDarkOrange 4px solid;

                    &::before {
                        content: '\f0da' !important;
                        font-family: "Font Awesome 6 Pro";
                        color: $colDarkOrange !important;
                        position: absolute !important;
                        top: 7px !important;
                        left: -5px !important;
                        font-size: 22px !important;
                        text-shadow: none;
                    }
                }
            }

            .accordion-button {
                background-color: #f3f3f4;
                font-size: 13px;
                padding: 12px;
                border-radius: 0%;
                position: relative;
                background: #f3f3f4 !important;
                color: $colDarkTeal !important;
                font-weight: 600 !important;
                text-transform: uppercase;
                position: relative !important;
                overflow: visible !important;
                text-shadow: none;
                padding-left: 20px;

                &::after {
                    content: '\f13a';
                    font-family: "Font Awesome 6 Pro";
                    color: #aaa;
                    font-size: 18px;
                    background-image: none !important;
                }
            }
        }
    }
}

.achievement-container {
    background-color: #fff;
    padding: 12px 0;

    .colCount {
        font-size: 30px;
        font-weight: 300;
        color: #f1592a;
    }
}

.password-container {
    color: $colDarkTeal;

    label {
        color: #000;
    }

    .passtrengthMeter {
        &::after {
            left: 0;
            height: 2px;
        }

        .tooltip {
            top: 30px !important;
            margin-left: -50% !important;
        }
    }

    a {
        background-color: #e8e8e9;
        color: #fff;
        text-align: center;
        padding: 5px 7px;
        border-radius: 50%;

        &.active {
            background-color: #000;
        }

    }

    li {
        &.valid {
            color: green;

            &::before {
                content: '\f00c';
                font-family: "Font Awesome 6 Pro";
                position: relative;
                left: -4px;
                font-weight: 700;
            }
        }

        &.invalid {
            color: red;

            &::before {
                content: '\f00d';
                font-family: "Font Awesome 6 Pro";
                position: relative;
                left: -4px;
                font-weight: 700;
            }
        }
    }

}