.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.btn-circular-post{
  display: block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  color: #f1592a;
  box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.1);
}

.btn-circular-post img{
  height: 20px;
  width: auto;
  /* padding-top: 10px; */
}

.dd-select {
  background-color: #f3f3f3;
  width: 100%;
  min-width: 180px;
  height: 35px;
  text-align: center;
  border-radius: 5px;
  border: none;
  box-shadow: 0px 1px 3px 2px rgba(0,0,0,0.1);
}

.boxi {
  margin: 0.4em;
  border-radius: 1em;
  background-color: #f3f3f3;
  border: 0.8px solid black;
  flex-basis: 22%;
  padding: 0.4em;
  text-shadow: none;
  color: #000;

  /* font-weight: bold; */
}

.flex-box4 {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  min-height: 100%;
  margin: 0px auto;
  justify-content: center;
  text-shadow: none;
  
}

.view-rank-instruction {
  font-size: 0.75em;
  border-bottom: 1px solid gray;
}

#rankHolder,
.rating-container {
  border: 1px solid #d4d5d7;
  background-color: #f3f3f3;
  border-radius: 4px;
  padding: 6px;
}

.full-width-title {
  border: none;
  background-color: #f1592a;
  color: #fff;
  margin: 0;
  padding: 5px 0;
  text-align: center;
  text-align: center;
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 20px;
  margin-top: 6px;
}

.ranking-list {
  width: 100%;
  font-family: "Montserrat";
  font-weight: 300;
  text-transform: none;
  vertical-align: top;
  text-align: left;
  border-collapse: collapse;
  color: #001f3e;
  font-size: 13px;
}
.ranking-list td {
  vertical-align: top !important;
  padding-top: 8px;
  color: #001f3e;
}
.ranking-list td b {
  color: #00363b;
}
.ranking-list th {
  color: #bcbec0;
  font-weight: bold;
  border-bottom: 1px solid #bcbec0;
  padding: 4px 0 6px 0;
  margin: 0 0 12px 0;
}

.container-nav-training {
  border-left: 3px solid #f1592a;
  background-color: #f3f3f4;
  color: #231f20;
  margin: 0;
  padding: 0 0 10px 0;
  text-align: left;
}

.container-nav-training h3 {
  margin: 6px 8px 12px 10px;
  padding: 8px 0 0 8px;
  text-align: left;
  color: #00363b !important;
  font-weight: 600 !important;
  font-size: 14px;
  position: relative;
}

.container-nav-training h3::before {
  content: "\f0da";
  font-family: "Font Awesome 6 Pro";
  color: #f1592a;
  position: absolute;
  top: 4px;
  left: -30px;
  font-size: 22px;
  text-shadow: none;
}

.container-nav-training  p {
  padding-top: 0;
  margin-top: 0;
  padding-left: 10px;
  width: calc(70% - 10px);
  float: left;
}

.container-nav-training .nav-link {
  width: calc(30% - 20px);
  float: left;
  display: block;
  text-align: right;
  position: relative;
  padding: 70px 10px 0 10px;
  color: #231f20 !important;
  font-weight: normal;
}

.container-nav-training .nav-link .img-responsive {
  display: block;
  max-width: 100px;
  height: auto;
  right: -5px;
  top: 0px;
  /*left: auto;
  float: right !important;*/
  position: absolute;
}

.container-nav-training .icon-container {
  float: left;
  padding: 0;
  margin: 0;
  width: 20%;
  /*border: 1px solid #f0f;*/
}
.container-nav-training .icon-container .img-responsive {
  display: block;
  max-width: calc(100% - 20px);
  height: auto;
}
.container-nav-training .more-container {
  width: 50px;
  float: left;
  /*border: 1px solid #f0f;*/
  text-align: center;
}

.container-nav-training .more-container a {
  color: #f28d20;
  font-size: 11px;
  text-decoration: none;
  text-align: center;
}

.container-nav-training .nav-link i {
  color: #f1592a;
  margin-top: 4px;
}
