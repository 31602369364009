$rankWidth: 50px;
$rankHeight: 25px;

.ranking {
    width: $rankWidth;
    height: $rankHeight;
    &-1 {
       
        background: url(../../public/img/level-icons/inactive/level-1.png) no-repeat center center;
        background-size: $rankWidth $rankHeight;

        &.active {
            background: url(../../public/img/level-icons/active/level-1.png) no-repeat center center;
            background-size: $rankWidth $rankHeight;
        }
    }

    &-2 {
        background: url(../../public/img/level-icons/inactive/level-2.png) no-repeat center center;
        background-size: $rankWidth $rankHeight;

        &.active {
            background: url(../../public/img/level-icons/active/level-2.png) no-repeat center center;
            background-size: $rankWidth $rankHeight;
        }
    }

    &-3 {
        background: url(../../public/img/level-icons/inactive/level-3.png) no-repeat center center;
        background-size: $rankWidth $rankHeight;

        &.active {
            background: url(../../public/img/level-icons/active/level-3.png) no-repeat center center;
            background-size: $rankWidth $rankHeight;
        }
    }

    &-4 {
       
        background: url(../../public/img/level-icons/inactive/level-4.png) no-repeat center center;
        background-size: $rankWidth $rankHeight;

        &.active {
            background: url(../../public/img/level-icons/active/level-4.png) no-repeat center center;
            background-size: $rankWidth $rankHeight;
        }
    }

    &-5 {
        background: url(../../public/img/level-icons/inactive/level-5.png) no-repeat center center;
        background-size: $rankWidth $rankHeight;

        &.active {
            background: url(../../public/img/level-icons/active/level-5.png) no-repeat center center;
            background-size: $rankWidth $rankHeight;
        }
    }

    &-6 {
        background: url(../../public/img/level-icons/inactive/level-6.png) no-repeat center center;
        background-size: $rankWidth $rankHeight;

        &.active {
            background: url(../../public/img/level-icons/active/level-6.png) no-repeat center center;
            background-size: $rankWidth $rankHeight;
        }
    }

    &-7 {
        background: url(../../public/img/level-icons/inactive/level-7.png) no-repeat center center;
        background-size: $rankWidth $rankHeight;

        &.active {
            background: url(../../public/img/level-icons/active/level-7.png) no-repeat center center;
            background-size: $rankWidth $rankHeight;
        }
    }
}