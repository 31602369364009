.addInsightContent {
    h1 {
        text-shadow: none;
        font-weight: 400 !important;
        font-size: 26px;
        padding: 0.5rem 0;
        margin: 0;
        color: #0e76bc;
        text-align: center;
        text-transform: uppercase;
        background-color: #fff;
    }
}