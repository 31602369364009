.pac-container {
  z-index: 9999 !important;
}

.walkthrough-container {
  .modal-header {
    background-color: #00363b;
    color: #fff;
    text-align: center;
    padding: 10px 0;
    display: inline;
  }
}
